import { ThemeEngineTheme } from '@/store/modules/Settings/Application/ThemeEngine/types'
import colors from 'vuetify/lib/util/colors'

export default {
  light: {
    primary: colors.blue.darken3,
    secondary: colors.blue.lighten1,
    background: colors.blue.lighten3,
    accent: colors.blue.darken4,
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  },
  dark: {
    primary: colors.blue.darken4,
    secondary: colors.blue.darken3,
    background: colors.blue.darken1,
    accent: colors.lightBlue.lighten2,
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  }
} as ThemeEngineTheme
