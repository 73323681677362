import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { LocalizationState } from './types'
import { BaseState } from '../../../../types'
import { LoadedLocalization } from '@/plugins/i18n'

export const defaultState = (): LocalizationState => {
  return {
    currentLocale: 'auto',
    languages: LoadedLocalization
  }
}

export const state = defaultState()

export const Localization: Module<LocalizationState, BaseState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
