import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import { guessBrowserLanguage, LocaleInfo } from './helper'
Vue.use(VueI18n)

// variable to store messages and info for auto load locale
const messages: LocaleMessages = {}
const register: LocaleInfo[] = []

// require *.ts from locales folder
const files = require.context('@/locales', false, /\.json$/)
// try to register and load every locale found in folder
files.keys().forEach(key => {
  const locale = files(key)
  const info:LocaleInfo = new LocaleInfo({ title: locale?.locale?.name, flag: locale?.locale?.flag, language: locale?.locale?.languageCode })
  console.log('LocaleInfo:', info)
  const localeName = key.replace(/(\.\/|\.json)/g, '')

  // if we don't have flag set to the localeName (filesystem name without extension)
  if (info.flag === '') {
    info.flag = localeName
  }
  // if we don't have language identifier set to the localeName (filesystem name without extension
  if (info.language === '') {
    info.language = localeName
  }

  register.push(info)
  // store messages on global messages obj
  messages[info.language] = locale
})

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-US',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages, // set locale messages
  silentTranslationWarn: true
})

export default i18n

export const LoadedLocalization = register

export const defaultLocale = function (): string {
  return guessBrowserLanguage(LoadedLocalization, '' + i18n.fallbackLocale)
}
