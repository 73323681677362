
import { CollectionMetadata, VolumeMetadata, RecentsCollectionId } from '@/store/modules/Settings/Reader/Books/types'
import Vue from 'vue'
import Component from 'vue-class-component'
import ClosedBook from '@/components/img/ClosedBook.vue'

@Component({ components: { ClosedBook }, data: () => ({ RecentsCollectionId }) })
export default class Home extends Vue {
  plugins = []

  get collections (): CollectionMetadata[] {
    return this.$store.getters['Settings/Reader/Books/collections']
  }

  volumes (collection: string): VolumeMetadata[] {
    return this.$store.getters['Settings/Reader/Books/volumes'](collection)
  }

  volumeProgress (collectionId:string, volumeId:string): number {
    return this.$store.getters['Settings/Reader/Books/getVolumeProgress']({ collectionId, volumeId })
  }

  routeToReader (collection: string, volume: string, disabled: boolean):void {
    if (!disabled) this.$router.push('/reader/' + collection + '/' + volume)
  }

  isNotTouch = !window.matchMedia('(pointer: coarse)').matches

  mounted ():void {
    this.$store.commit('Settings/Application/TitleBar/SET_SHOW_TITLE_BAR', true)
    window.matchMedia('(pointer: coarse)').addEventListener('change', (e) => {
      this.isNotTouch = !e.matches
    })
  }
/*  get cardStyle ():Record<string, string | number> {
    const base:Record<string, string | number> = {
      'max-height': '30vh',
      margin: '1em'
    }

    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return { ...base, width: '50vh' }
      case 'sm':
      case 'md':
        return { ...base, width: '40vh' }
      case 'lg':
      case 'xl':
        return { ...base, width: '30vh' }
    }
  } */
}
