
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import YNDialog from '@/components/YNDialog.vue'

@Component({ components: { YNDialog } })
export default class ResetSettings extends Vue {
  public static col = 2
  public static row = 1
    @Prop({ type: Number, default: 10 })
      elevation!: number

    isResetDialogOpen = false
    resetServersToo = false

    openPopUp ():void {
      this.isResetDialogOpen = true
    }

    reset ():void {
      this.$store.commit('SET_RESET_PARTIAL', !this.resetServersToo)
      this.$store.commit('RESET_STORE')
    }
}
