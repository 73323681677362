
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({ })
export default class YNDialog extends Vue {
    @Prop({ type: Boolean, default: false })
      isOpen!:boolean

    @Prop({ type: String, default: null })
      title!:string

    @Prop({ type: Number, default: 450 })
      width!:number

    @Prop({ type: String, default: null })
      yIcon!:string

    @Prop({ type: String, default: null })
      nIcon!:string

    get dialog ():boolean {
      return this.isOpen
    }

    set dialog (value:boolean) {
      if (value === true) { this.$emit('open') }
      if (value === false) { this.$emit('close') }
    }

    yesFun ():void {
      this.$emit('accepted')
      this.dialog = false
    }

    noFun ():void {
      this.$emit('cancelled')
      this.dialog = false
    }
}
