
import Vue from 'vue'
import VDraggable from 'vuedraggable'
import { Component, Prop } from 'vue-property-decorator'
import { DraggableGridObj, armonizeCol } from './DraggableGridHelper'

@Component({ components: { VDraggable } })
export default class DraggableGrid extends Vue {
  drag = false
  enabled = false

  armonizeCol = armonizeCol

  get hasSubheader () :boolean {
    if (typeof this.$slots !== 'undefined') {
      return typeof this.$slots.subheader !== 'undefined'
    }
    return false
  }

  get rowNumber (): number {
    return this.elementsMap.elements.length
  }

  get currentDistance (): number {
    return this.$vuetify.application.top + (this.$store.state.Settings.Application.SystemTitleBar.applicationBorder * 2)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get dragOptions () {
    return {
      animation: 300,
      handle: '.handle',
      ghostClass: 'ghost'
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get innerDragOptions () {
    return {
      animation: 300,
      handle: '.row-handle',
      ghostClass: 'ghost'
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  cols (values: any) {
    if (values.length <= 0) {
      return [{ placeholder: true }]
    }
    return values
  }

  mounted (): void {
    this.enabled = this.dragEnabled
  }

  @Prop({
    type: DraggableGridObj,
    default: () => {
      return new DraggableGridObj()
    }
  })
    elementsMap!: DraggableGridObj

  // Applies the align-items css property. Available options are start, center, end, baseline and stretch.
  @Prop({ type: String, default: undefined })
    align!: string

  // Applies the align-content css property. Available options are start, center, end, space-between, space-around and stretch.
  @Prop({ type: String, default: undefined })
    'align-content'!: string

  // Changes the align-content property on large and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-content-lg'!: string

  // Changes the align-content property on medium and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-content-md'!: string

  // Changes the align-content property on small and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-content-sm'!: string

  // Changes the align-content property on extra large and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-content-xl'!: string

  // Changes the align-items property on large and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-lg'!: string

  // Changes the align-items property on medium and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-md'!: string

  // Changes the align-items property on small and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-sm'!: string

  // Changes the align-items property on extra large and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'align-xl'!: string

  // Applies the justify-content css property. Available options are start, center, end, space-between and space-around.
  @Prop({ type: String, default: undefined })
    justify!: string

  // Changes the justify-content property on large and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'justify-lg'!: string

  // Changes the justify-content property on medium and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'justify-md'!: string

  // Changes the justify-content property on small and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'justify-sm'!: string

  // Changes the justify-content property on extra large and greater breakpoints.
  @Prop({ type: String, default: undefined })
    'justify-xl'!: string

  // Sets the default offset for the column.
  @Prop({ type: [String, Number], default: undefined })
    offset!: string | number

  // Changes the offset of the component on large and greater breakpoints.
  @Prop({ type: [String, Number], default: undefined })
    'offset-lg'!: string | number

  // Changes the offset of the component on medium and greater breakpoints.
  @Prop({ type: [String, Number], default: undefined })
    'offset-md'!: string | number

  // Changes the offset of the component on small and greater breakpoints.
  @Prop({ type: [String, Number], default: undefined })
    'offset-sm'!: string | number

  // Changes the offset of the component on extra large and greater breakpoints.
  @Prop({ type: [String, Number], default: undefined })
    'offset-xl'!: string | number

  // Reduces the gutter between v-cols.
  @Prop({ type: Boolean, default: false })
    dense!: boolean

  @Prop({ type: Boolean, default: false })
    dragEnabled!: boolean

  // Removes the gutter between v-cols.
  @Prop({ type: Boolean, default: false })
    'no-gutters'!: boolean

  // Specify a custom tag used on the root element.
  @Prop({ type: String, default: 'div' })
    'row-tag'!: string

  // Specify a custom tag used on the root element.
  @Prop({ type: String, default: 'div' })
    'col-tag'!: string
}
