import { MutationTree } from 'vuex'
import { BaseState } from './types'

export const mutations: MutationTree<BaseState> = {
  RESET_STORE: function (state) {
    state.resetStore = true
    location.reload()
  },
  SET_RESET_PARTIAL: function (state, val) {
    state.resetPartial = val
  }
}
