
export function compareInt (a: number, b: number, desc: boolean): -1 | 0 | 1 {
  if (desc) {
    if (a < b) return 1
    if (a > b) return -1
  } else {
    if (a < b) return -1
    if (a > b) return 1
  }
  return 0
}
export function camelToSnake (str: string):string {
  return str.replace(/[\w]([A-Z])/g, function ([newStrA, newStrB]) {
    return newStrA + '_' + newStrB
  }).toLowerCase()
}
export function snakeToCamel (str: string):string {
  return str.replace(/([-_][a-z])/ig, (newStr: string) => {
    return newStr.toUpperCase().replace('-', '').replace('_', '')
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapModulesDefaultState (): { [key: string]: any } {
  const files = require.context('./modules', true, /index.ts/)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj: {[key:string]:any} = {}
  for (const filePath of files.keys().reverse()) {
    const path = filePath.trim().replaceAll('\\', '/').substring(2).replace('/index.ts', '')
    // console.log('mapModulesDefaultState filePath:', path)
    let pointer = obj
    const curSubPaths = path.split('/')
    for (const [index, subPath] of curSubPaths.entries()) {
      if (curSubPaths.length - 1 === index) {
        pointer[subPath] = files(filePath).defaultState()
      }
      if (typeof pointer[subPath] !== 'object') {
        pointer[subPath] = {}
      }
      pointer = pointer[subPath]
    }
  }
  return obj
}
