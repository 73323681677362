
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { LocaleInfo } from '@/plugins/i18n/helper'
import Flag, { FlagType } from '@/components/Flag.vue'

const storePrefix = 'Settings/Application/'

@Component(
  {
    computed: {
      ...mapState(storePrefix + 'Localization', ['languages'])
    },
    components: {
      Flag
    }
  }
)
export default class LanguageIcon extends Vue {
    @Prop({ type: String, default: 'auto' })
      language!:string

    @Prop({ type: String, default: '' })
      appendLabel!:string

    @Prop({ type: String, default: '' })
      elClass!:string

    @Prop({ type: String, default: 'mdi-web' })
      automaticIcon!:string

    @Prop({ type: Number, default: 24 })
      size!:number

    @Prop({ type: Number, default: FlagType.Normal })
      type!: FlagType

    languages!: LocaleInfo[]

    isoOf (language:string): string {
      for (const el of this.languages) {
        if (el.language === language) {
          return el.flag
        }
      }
      return language
    }
}
