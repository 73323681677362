
import {
  ThemeEngineThemeItem,
  ThemeEngineThemeVariant,
  ThemeEngineThemeView,
  ThemeEngineVariationType
} from '@/store/modules/Settings/Application/ThemeEngine/types'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

const storePrefix = 'Settings/Application/'

@Component({ })
export default class VThemeBoxPreviewer extends Vue {
  @Prop({ type: Object, default: function () { return null } })
    theme!: ThemeEngineThemeView

  @Prop({ type: Number, default: 48 })
    size!: number

  @Prop({ type: Boolean, default: false })
    onlyShow!: boolean

  @Prop({ type: String, default: '' })
    appendLabel!:string

  get currentTheme (): ThemeEngineThemeView {
    return this.$store.getters[storePrefix + 'ThemeEngine/currentThemeView']
  }

  set currentTheme (theme: ThemeEngineThemeView) {
    if (!this.onlyShow) {
      this.$emit('change', theme.key, this.$store.state.Settings.Application.ThemeEngine.currentTheme)
      this.$store.dispatch(storePrefix + 'ThemeEngine/setCurrentTheme', theme.key)
    }
  }

  get showTheme (): ThemeEngineThemeView {
    if (this.theme && this.theme !== null) {
      return this.theme
    }
    return this.currentTheme
  }

  get currentVariationType (): ThemeEngineVariationType {
    return this.$store.getters[storePrefix + 'ThemeEngine/currentVariationType']
  }

  themeVariant (theme: ThemeEngineThemeView): ThemeEngineThemeVariant {
    const saned = {} as ThemeEngineThemeVariant
    const cur = theme[this.currentVariationType] as ThemeEngineThemeVariant
    for (const themeKey of Object.keys(cur)) {
      const curKey: ThemeEngineThemeItem = cur[themeKey]
      // console.log('typeof curKey =', typeof curKey)
      if (typeof curKey !== 'undefined') {
        if (typeof curKey === 'string' || typeof curKey === 'number') {
          saned[themeKey] = curKey
        } else {
          saned[themeKey] = curKey.base
        }
      }
    }
    return saned
  }
}
