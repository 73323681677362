import Vue from 'vue'
import Vuex from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { BaseState } from './types'
import { Settings } from './modules/Settings'
import createPersistedState from 'vuex-persistedstate'
import { mapModulesDefaultState } from './helpers'
import Reducer from './reducer'

Vue.use(Vuex)

const reducerResetToDefault = [
  // 'availablePages',
  'Settings/Application/SystemTitleBar/appName',
  'Settings/Application/ThemeEngine',
  'Settings/Application/Localization/languages',
  'isLoading',
  'disconnected',
  'WindowManager',
  'Settings/Reader/Books/isReading'
]
const reducerResetToDefaultWhitelist = ['Settings/Application/ThemeEngine/currentTheme', 'Settings/Application/ThemeEngine/isDark', 'Settings/Application/ThemeEngine/darkState']
const reducerWhitelistOnReset: string[] = [
  // 'acsm'
  'Settings/Application/SystemTitleBar/currentWindowName'
]
const reducerMapPropToProp: { from:string, to:string}[] = [
  // { from: 'acsm/preferredServer', to: 'acsm/currentServer' }
]
const resetProp = 'resetStore'
const resetPartialProp = 'resetPartial'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const entryPoint: Array<(newVal:any, defState:any)=>void> = [
  // function (newState, defState) {
  //   // console.log('new', newState)
  //   // console.log('def', defState)
  // }
]

const re = new Reducer({
  blacklist: reducerResetToDefault,
  blacklistIgnore: reducerResetToDefaultWhitelist,
  whitelist: reducerWhitelistOnReset,
  maplist: reducerMapPropToProp,
  resetProp,
  resetPartialProp,
  entryPoint,
  log: false
})

export const defaultState = (): BaseState => {
  return {
    disconnected: {
      isDisconnected: false,
      message: ''
    },
    isLoading: true,
    resetStore: false,
    resetPartial: true
  }
}

export const state = defaultState()

export default new Vuex.Store<BaseState>({
  strict: (process.env.NODE_ENV === 'development'),
  actions,
  state,
  getters,
  mutations,
  modules: {
    Settings
  },
  plugins: [
    createPersistedState({ reducer: re.reduce })
  ]
})

console.log('mapModulesDefaultState() => ', mapModulesDefaultState())
