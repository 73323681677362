import { ActionTree } from 'vuex'
import { SettingsState } from './types'
import { BaseState } from '../../types'

export const actions: ActionTree<SettingsState, BaseState> = {
  load: async ({ dispatch }) => {
    await dispatch('Reader/load')
    await dispatch('Application/load')
  }
}
