import { ActionTree } from 'vuex'
import { ReaderSettingsState } from './types'
import { BaseState } from '../../../types'

export const actions: ActionTree<ReaderSettingsState, BaseState> = {
  load: async ({ dispatch }) => {
    await dispatch('Books/load')
    await dispatch('Lexer/load')
  }
}
