import Vue from 'vue'
import { MutationTree } from 'vuex'
import { BooksState, RecentsCollectionId, VolumeIdentifier } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sortArr<T> (arr: Array<T>, isDesc: boolean, getEleFun: (e: T) => any = (e) => e): Array<T> {
  return arr.sort((a, b) => {
    const ra = getEleFun(a)
    const rb = getEleFun(b)
    if (ra === rb) return 0
    if (ra < rb) return isDesc ? 1 : -1
    if (ra > rb) return isDesc ? -1 : 1
    return 0
  })
}

export const mutations: MutationTree<BooksState> = {
  SET_IS_READING (state, isReading) {
    state.isReading = !!isReading
  },
  SET_COLLECTION (state, { collectionName, collection }) {
    Vue.set(state.library, collectionName, collection)
  },
  SET_COLLECTION_BOOK (state, { collectionName, bookName, book }) {
    Vue.set(state.library[collectionName].volumes, bookName, book)
  },
  SET_RECENTS_BOOKS_SORT_DESC (state, sortDesc: boolean) {
    state.lastBooksSortDesc = sortDesc
    state.lastBooks = sortArr(state.lastBooks, state.lastBooksSortDesc, (e) => e.time)
  },
  ADD_BOOK_TO_RECENTS (state, book: VolumeIdentifier) {
    const curTime = new Date()
    if (book.collectionId === RecentsCollectionId) {
      try {
        const [collId, volId] = book.volumeId.split('-', 2)
        book.collectionId = collId
        book.volumeId = volId
      } catch (e) { }
    }
    state.lastBooks = state.lastBooks.filter(curBook => !(curBook.book.collectionId === book.collectionId && curBook.book.volumeId === book.volumeId))
    state.lastBooks.unshift({ time: curTime, book })
    state.lastBooks = sortArr(state.lastBooks, state.lastBooksSortDesc, (e) => e.time)
    if (state.lastBooks.length > state.lastBooksMaxItems) state.lastBooks.length = state.lastBooksMaxItems
  },
  SET_VOLUME_POSITION (state, { volume, position }) {
    state.booksPosition[volume.collectionId + '-' + volume.volumeId] = position || { chapter: 0, page: 0, size: 0 }
  },
  SET_VOLUME_CHAPTER_POSITION (state, { volume, position }) {
    if (!(state.booksPosition[volume.collectionId + '-' + volume.volumeId])) {
      state.booksPosition[volume.collectionId + '-' + volume.volumeId] = { chapter: 0, page: 0, size: 0 }
    }
    state.booksPosition[volume.collectionId + '-' + volume.volumeId].chapter = position
  },
  SET_VOLUME_SIZE_POSITION (state, { volume, position }) {
    if (!(state.booksPosition[volume.collectionId + '-' + volume.volumeId])) {
      state.booksPosition[volume.collectionId + '-' + volume.volumeId] = { chapter: 0, page: 0, size: 0 }
    }
    state.booksPosition[volume.collectionId + '-' + volume.volumeId].size = position
  },
  SET_VOLUME_PAGE_POSITION (state, { volume, position }) {
    if (!(state.booksPosition[volume.collectionId + '-' + volume.volumeId])) {
      state.booksPosition[volume.collectionId + '-' + volume.volumeId] = { chapter: 0, page: 0, size: 0 }
    }
    state.booksPosition[volume.collectionId + '-' + volume.volumeId].page = position
  },
  CLEAR_RECENT_BOOKS (state) {
    state.lastBooks = []
  }
}
