
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class IconComponent extends Vue {
  @Prop({ type: String, default: '' })
    alt!:string

  @Prop({ type: String, default: '/img/vueIcons' })
    path!:string

  @Prop({ type: String, required: true })
    icon!:string

  @Prop({ type: String, default: 'svg' })
    extension!:string

  @Prop({ type: Boolean, default: false })
    tagFromExtension!:boolean

  @Prop({ type: Boolean, default: false })
    respectColor!:boolean

  @Prop({ type: Boolean, default: false })
    fetchSvg!:boolean

  fetched = false
  fetchErr = false

  fetchedContent = ''

  get showLoader ():boolean {
    return this.fetchSvg && !this.fetched && !this.fetchErr
  }

  get tag ():string {
    if (this.tagFromExtension) {
      if (this.extension === 'svg') {
        return 'object'
      }
    }
    return 'img'
  }

  get componentOptions ():{[key:string]:string | boolean | number} {
    if (this.tagFromExtension) {
      if (this.tag === 'object') {
        return {
          alt: this.alt,
          data: this.srcIcon,
          class: this.iconClass,
          type: 'image/svg+xml'
        }
      }
    }
    return {
      alt: this.alt,
      src: this.srcIcon,
      class: this.iconClass
    }
  }

  get srcIcon ():string {
    return this.path + '/' + this.icon + '.' + this.extension
  }

  get iconClass ():string {
    return this.respectColor ? 'svgfill' : ''
  }

  async mounted ():Promise<void> {
    if (this.fetchSvg) {
      try {
        const svgResponse = await fetch(this.srcIcon)
        this.fetchedContent = await svgResponse.text()
        this.fetched = true
      } catch (e) {
        this.fetchErr = true
      }
    }
  }
}
