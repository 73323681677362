import { Module, ModuleTree } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { SettingsState } from './types'
import { BaseState } from '../../types'
import { ApplicationSettings } from './Application'
import { ReaderSettings } from './Reader'
export const defaultState = (): SettingsState => ({
  showDialog: false
})

export const state = defaultState()

export const modules: ModuleTree<BaseState> = {
  Application: ApplicationSettings,
  Reader: ReaderSettings
}

export const Settings: Module<SettingsState, BaseState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}
