import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { DarkState, ThemeEngineState } from './types'
import { BaseState } from '../../../../types'
import themes from '@/themes'

export const defaultState = (): ThemeEngineState => {
  return {
    currentTheme: 'High Contrast',
    themeList: themes,
    isDark: true,
    darkState: DarkState.System
  }
}

export const state = defaultState()

export const ThemeEngine: Module<ThemeEngineState, BaseState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
