/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * load settings component
 * list them
 * and reexport them
 */

import { DraggableGridElement, iDraggableMap, iMappableObj } from '../DraggableGridHelper'

// export interface iSettingsComponent {
//     [key: string]: iSettingsComponent | any
// }

// export class settingsComponent implements iSettingsComponent {
//     [key: string]: settingsComponent
//     private _component: any
//     private _componentName: string
//     component (): any {
//       if (this._component) {
//         return { [this._componentName]: this._component }
//       }
//     }
// }

// {application:{name:'',component:''}}

function between (str:string, openTag: string, closeTag: string): string {
  return str.substring(str.indexOf(openTag) + openTag.length, str.indexOf(closeTag))
}
function setOnArrayAndMove<T> (arr: T[], ele: T, position: number) {
  if (typeof arr[position] === 'undefined') {
    arr[position] = ele
  } else {
    const oldEle = arr[position]
    arr[position] = ele
    let found = false
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === 'undefined') {
        arr[i] = oldEle
        found = true
        break
      }
    }
    if (!found) {
      arr.push(oldEle)
    }
  }
}

function loadSettings (): {exports: {[key:string]:any}, grids: iDraggableMap} {
  const settingsComponent = require.context('.', true, /[A-Za-z0-9-_,\s]+\.vue$/i)
  const components: { exports: { [key: string]: any }, grids: iDraggableMap } = { exports: {}, grids: {} }
  settingsComponent.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const name = matched[1]
      const group = between(key, './', '/' + name).toLocaleLowerCase()
      components.exports[name] = settingsComponent(key).default

      let curGridGroup = components.grids[group]
      if (typeof curGridGroup === 'undefined') {
        components.grids[group] = [[]]
        curGridGroup = components.grids[group]
      }
      if (typeof curGridGroup !== 'undefined') {
        const mapObj = components.exports[name] as iMappableObj
        if (typeof mapObj.col === 'number') {
          if (typeof curGridGroup[mapObj.col - 1] === 'undefined') curGridGroup[mapObj.col - 1] = []
          if (typeof mapObj.row === 'number') {
            setOnArrayAndMove(curGridGroup[mapObj.col - 1], name, mapObj.row - 1)
          } else {
            curGridGroup[mapObj.col - 1].push(name)
          }
        } else {
          curGridGroup[0].push(name)
        }
      }
    }
  })
  // console.log('messages:', messages)
  return components
}

export default loadSettings()
