import { GetterTree } from 'vuex'
import { SettingsState, SettingsTabs } from './types'
import { BaseState } from '../../types'
import { modules } from '.'

export const getters: GetterTree<SettingsState, BaseState> = {
  types (state): SettingsTabs[] {
    if (typeof modules !== 'undefined') {
      return Object.keys(modules).map((val) => {
        return {
          key: val.toLowerCase(),
          icon: state[val].typeIcon
        }
      })
    }
    return []
  },
  tabsName (): string[] {
    if (typeof modules !== 'undefined') {
      return Object.keys(modules)
    }
    return []
  }
}
