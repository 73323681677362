import { ThemeEngineList, ThemeEngineTheme, ThemeEngineThemeVariant } from '@/store/modules/Settings/Application/ThemeEngine/types'
import rgb2hex from 'rgb2hex'
import hex2rgb from 'hex2rgb'
import { getContrastingColor } from 'contra-color'
import { VuetifyThemeItem } from 'vuetify/types/services/theme'

function inverseHex (theme: VuetifyThemeItem): string {
  if (typeof theme === 'undefined') return ''
  const hex = typeof theme === 'string' || typeof theme === 'number' ? '' + theme : theme.base || ''
  return getContrastingColor(hex).color
}

function constrastTextTheme (theme: ThemeEngineThemeVariant): void {
  for (const key of Object.keys(theme)) {
    if (!key.includes('-contrast') && typeof theme[key + '-contrast'] === 'undefined') {
      theme[key + '-contrast'] = inverseHex(theme[key])
    }
  }
}

const curList: ThemeEngineList = {}

const indexName = 'index.ts'

const themes = require.context('.', false, /\.ts$/)

themes.keys().forEach(fileName => {
  // console.log('fileName:', fileName)
  if (!fileName.includes(indexName)) {
    let themeName = fileName.replace(/(\.\/|\.ts)/g, '')
    const themeExports = themes(fileName)
    if (themeExports && themeExports.themeName) {
      themeName = themeExports.themeName
    }
    const curTheme: ThemeEngineTheme = themeExports.default
    constrastTextTheme(curTheme.light)
    constrastTextTheme(curTheme.dark)
    curList[themeName] = curTheme
  }
})

export default curList
