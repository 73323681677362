
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

export enum FlagType{Normal, Squared, Rounded}

@Component({})
export default class FlagMessage extends Vue {
  @Prop({ type: String, default: null })
    iso!: string

  @Prop({ type: String, default: null })
    title!: string

  @Prop({ type: Number, default: FlagType.Normal })
    type!: FlagType

  @Prop({ type: Number, default: 16 })
    size!: number

  get flagIconClass (): string {
    let type = ''
    switch (this.type) {
      case FlagType.Squared:
        type = 'flag-icon-squared'
        break
      case FlagType.Rounded:
        type = 'flag-icon-squared flag-icon-rounded'
        break
      case FlagType.Normal:
      default:
        break
    }

    return type + ' flag-icon-' + this.iso.toLowerCase()
  }

  get flagIconStyle ():string {
    return 'font-size: ' + this.size + 'px'
  }
}
