import { ThemeEngineTheme } from '@/store/modules/Settings/Application/ThemeEngine/types'
import colors from 'vuetify/lib/util/colors'

export default {
  light: {
    primary: '#e2e2e2',
    'primary-contrast': '#000000',
    secondary: '#ffffff',
    'secondary-contrast': '#000000',
    background: '#ffffff',
    accent: '#000000',
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  },
  dark: {
    primary: '#1b1b1b',
    'primary-contrast': '#ffffff',
    secondary: '#000000',
    'secondary-contrast': '#ffffff',
    background: '#303030',
    accent: '#ffffff',
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  }
} as ThemeEngineTheme

export const themeName = 'High Contrast'
