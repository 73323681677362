import { MutationTree } from 'vuex'
import { LocalizationState } from './types'
import { guessBrowserLanguage } from '@/plugins/i18n/helper'
import i18n from '@/plugins/i18n'

export const mutations: MutationTree<LocalizationState> = {
  SET_CURRENT_LANGUAGE (state, newLang:string) {
    if (newLang !== '') {
      state.currentLocale = newLang
      if (newLang === 'auto') {
        i18n.locale = guessBrowserLanguage(state.languages, i18n.fallbackLocale as string)
      } else {
        i18n.locale = newLang
      }
    }
  }
}
