
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Component, Prop } from 'vue-property-decorator'
import { DarkState, ThemeEngineViewList } from '@/store/modules/Settings/Application/ThemeEngine/types'
import YNDialog from '@/components/YNDialog.vue'
import VThemeBoxPreviewer from '@/components/VThemeBoxPreviewer.vue'

const storePrefix = 'Settings/Application/'

@Component({
  computed: {
    ...mapGetters(storePrefix + 'ThemeEngine', ['themeListWithKey'])
  },
  components: {
    YNDialog,
    VThemeBoxPreviewer
  }
})
export default class ThemeSetting extends Vue {
  @Prop({ type: Number, default: 10 })
    elevation!: number

  themeListWithKey!: ThemeEngineViewList

  public static col = 1
  public static row = 1
  private themeState: number =
    typeof this.$store.state.Settings?.Application?.ThemeEngine?.darkState === 'string'
      ? DarkState[this.$store.state.Settings?.Application?.ThemeEngine?.darkState]
      : this.$store.state.Settings?.Application?.ThemeEngine?.darkState

  get darkThemeState (): number {
    return this.themeState
  }

  set darkThemeState (state: number) {
    if (typeof state !== 'undefined') {
      this.themeState = state
      console.log('state:', state, 'this.darkThemeStates[state]:', this.darkThemeStates[state])
      this.$store.dispatch(storePrefix + 'ThemeEngine/setDarkState', this.darkThemeStates[state].name)
    }
  }

  get currentTheme (): string {
    return this.$store.state.Settings?.Application?.ThemeEngine?.currentTheme
  }

  get darkThemeStates (): { name: string; icon: string, color: string }[] {
    return [
      { name: 'Dark', icon: '$fullMoon', color: 'black' },
      { name: 'Light', icon: 'mdi-white-balance-sunny', color: 'yellow' },
      { name: 'System', icon: '$autoDarkLight', color: 'primary-contrast' }
    ]
  }
}
