import { GetterTree } from 'vuex'
import { BooksState, CollectionMetadata, VolumeCurrentPosition, VolumeIdentifier, VolumeMetadata, RecentsCollectionId } from './types'
import { BaseState } from '../../../../types'

export const getts: GetterTree<BooksState, BaseState> = {

  libraryWithRecents (state, getters): Record<string, CollectionMetadata> {
    const lib = Object.assign({}, state.library)
    if (state.lastBooks.length > 0) {
      const recents = getters.recentsAsCollection as CollectionMetadata
      lib[recents.id] = recents
    }
    return lib
  },
  collections (state, getters): CollectionMetadata[] {
    return Object.keys(getters.libraryWithRecents).map(key => getters.libraryWithRecents[key]).sort((a: CollectionMetadata) => {
      if (a.id === RecentsCollectionId) return -1
      return 0
    })
  },
  currentBook (state): VolumeIdentifier | undefined {
    if (state.isReading) {
      const volumes = state.lastBooks
      if (volumes && volumes.length > 0) {
        return volumes[0].book
      }
    }
  },
  volumes: (state, getters) => (collection: string): (VolumeMetadata | undefined)[] => {
    return Object.keys(getters.libraryWithRecents[collection].volumes).map(key => getters.getVolume({ collectionId: collection, volumeId: key }))
  },
  getVolume: (state, getters) => {
    const fun = (volume: VolumeIdentifier): VolumeMetadata | undefined => {
      const coll = getters.libraryWithRecents[volume.collectionId]
      if (coll) {
        if (coll.type === 'markdownBook') {
          return (coll.volumes as Record<string, VolumeMetadata>)[volume.volumeId]
        } else if (coll.type === 'pointer') {
          const pointerBook = (coll.volumes as Record<string, VolumeIdentifier>)[volume.volumeId]
          const vol = fun(pointerBook)
          if (vol) {
            const obj = Object.assign({}, vol)
            obj.id = volume.volumeId
            obj.subTitle = obj.title
            obj.title = getters.libraryWithRecents[pointerBook.collectionId].title
            return obj
          }
        }
      }
    }
    return fun
  },
  getAdjacentVolumes: (_state, getters) => {
    const fun = (volume: VolumeIdentifier): {prev?:VolumeIdentifier, next?: VolumeIdentifier} => {
      const volumes = getters.volumes(volume.collectionId) as (VolumeMetadata | undefined)[]
      for (let i = 0; i < volumes.length; i++) {
        const vol = volumes[i]
        if (vol && vol.id === volume.volumeId) {
          const prevI = i - 1
          const nextI = i + 1
          const prev = prevI >= 0 ? volumes[prevI] : undefined
          const next = nextI < volumes.length ? volumes[nextI] : undefined
          const prevIdnt: VolumeIdentifier | undefined = prev && prev.id ? { collectionId: volume.collectionId, volumeId: prev.id } : undefined
          const nextIdnt: VolumeIdentifier | undefined = next && next.id ? { collectionId: volume.collectionId, volumeId: next.id } : undefined
          return { prev: prevIdnt, next: nextIdnt }
        }
      }
      return {}
    }
    return fun
  },
  getVolumePosition: (state, getters) => {
    const fun = (volume: VolumeIdentifier): VolumeCurrentPosition | undefined => {
      const coll = getters.libraryWithRecents[volume.collectionId]
      if (coll) {
        if (coll.type === 'markdownBook') {
          return state.booksPosition[volume.collectionId + '-' + volume.volumeId] || { chapter: 0, size: 0, page: 0 }
        } else if (coll.type === 'pointer') {
          return fun((coll.volumes as Record<string, VolumeIdentifier>)[volume.volumeId])
        }
      }
    }
    return fun
  },
  getVolumeProgress: (state, getters) => (id: VolumeIdentifier) => {
    const volume: VolumeMetadata | undefined = getters.getVolume(id)
    if (!(volume)) return 0
    const position: VolumeCurrentPosition | undefined = getters.getVolumePosition(id)
    if (!(position)) return 0
    const maxChaps = volume.tableOfContent.chapters.length
    const curChap = position.chapter
    const mainPerc = curChap * 100 / maxChaps
    const mainPercMin = 1 * 100 / maxChaps
    const curChapPages = position.size
    const curChapPage = position.page
    const chapPerc = curChapPage * mainPercMin / curChapPages
    const result = mainPerc + chapPerc
    return isNaN(result) ? 0 : result
  },
  recentsAsCollection (state): CollectionMetadata {
    const volumes:Record<string, VolumeIdentifier> = {}
    for (const { book } of state.lastBooks) {
      volumes[book.collectionId + '-' + book.volumeId] = book
    }

    return {
      id: RecentsCollectionId,
      title: 'reader.recentBooks.title',
      type: 'pointer',
      logo: '',
      volumes
    }
  }
}
