
import Vue from 'vue'
import SettingDialog from './SettingDialog.vue'
import { Component, Watch } from 'vue-property-decorator'
import { CollectionMetadata, VolumeCurrentPosition, VolumeIdentifier, VolumeMetadata } from '@/store/modules/Settings/Reader/Books/types'

@Component({ components: { SettingDialog } })
export default class BrandedAppBar extends Vue {
  get showSettings (): boolean {
    return this.$store.state.Settings.showDialog as boolean
  }

  set showSettings (show:boolean) {
    this.$store.commit('Settings/SET_SHOW_DIALOG', show)
  }

  get showBar ():boolean {
    return this.$store.state.Settings.Application.TitleBar.showTitleBar
  }

  get isReading ():boolean {
    return this.$store.state.Settings.Reader.Books.isReading
  }

  get currentBookIdentifier (): VolumeIdentifier | undefined { return this.$store.getters['Settings/Reader/Books/currentBook'] as VolumeIdentifier | undefined }
  get currentBook (): VolumeMetadata | undefined { return this.$store.getters['Settings/Reader/Books/getVolume'](this.currentBookIdentifier) as VolumeMetadata | undefined }
  get currentBookPosition (): VolumeCurrentPosition | undefined { return this.$store.getters['Settings/Reader/Books/getVolumePosition'](this.currentBookIdentifier) as VolumeCurrentPosition | undefined }
  get currentCollection (): CollectionMetadata | undefined { if (this.currentBookIdentifier) return this.$store.getters['Settings/Reader/Books/libraryWithRecents'][this.currentBookIdentifier.collectionId] }

  get bookTitle (): string {
    if (this.currentBook) {
      return this.currentBook.title
    }
    return ''
  }

  get bookArc (): string {
    if (this.currentBook) {
      const arc = this.currentBook.tableOfContent.arc
      if (arc !== '__full') return arc
    }
    return ''
  }

  get bookChapter ():string {
    if (this.currentBook && this.currentBookPosition) {
      const chapter = this.currentBook.tableOfContent.chapters[this.currentBookPosition.chapter].title
      if (chapter !== 'fullBook') return chapter
      return this.currentBook.subTitle ?? ''
    }
    return ''
  }

  @Watch('$i18n.locale', { immediate: true })
  setTitleOnPage (): void {
    const title = document.querySelector('html head title')
    if (title) {
      title.innerHTML = this.$t('title') as string
    }
  }

  mounted (): void {
    this.setTitleOnPage()
  }
}
