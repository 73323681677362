/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mapModulesDefaultState } from './helpers'
import { defaultState } from './index'
import { cloneDeep } from 'lodash'

export default class Reducer {
  blacklist: string[]
  blacklistIgnore: string[]
  whitelist: string[]
  maplist: { from: string, to: string }[]
  logs: boolean
  resetProp: string
  resetPartialProp: string
  entryPoint: Array<(newVal:any, defState:any)=>void>

  constructor ({ blacklist, blacklistIgnore, whitelist, maplist, log, resetPartialProp, resetProp, entryPoint }: {
      blacklist: string[],
      blacklistIgnore: string[],
      whitelist: string[],
      maplist: { from: string, to: string }[],
      log?: boolean,
      resetProp: string,
      resetPartialProp: string,
      entryPoint: Array<(newVal:any, defState:any)=>void>
  }) {
    this.blacklist = blacklist
    this.blacklistIgnore = blacklistIgnore
    this.maplist = maplist
    this.logs = (!!log)
    this.whitelist = whitelist
    this.resetProp = resetProp
    this.resetPartialProp = resetPartialProp
    this.entryPoint = entryPoint
    // console.log('constructor called: blacklist = ', blacklist)
  }

  reduce = (val:any):any => {
    this.log('val', val)
    let newVal = cloneDeep(val)
    this.log('newVal', newVal)
    const defState = { ...defaultState(), ...mapModulesDefaultState() }
    this.log('defaultState', defState)

    this.log('resetProp[', this.resetProp, '] =', Reducer.recurseObj(newVal, this.resetProp, '/'))
    if (this.resetProp && Reducer.recurseObj(newVal, this.resetProp, '/') === true) {
      this.log('resetPartialProp[', this.resetPartialProp, '] = ', Reducer.recurseObj(newVal, this.resetPartialProp, '/'))
      this.log('whitelist=', this.whitelist)
      this.log('if: ', this.resetPartialProp && this.whitelist && Reducer.recurseObj(newVal, this.resetPartialProp, '/') === true)
      if (this.resetPartialProp && this.whitelist && Reducer.recurseObj(newVal, this.resetPartialProp, '/') === true) {
        const whitelisted = {}
        for (const path of this.whitelist) {
          Reducer.setRecurseObj(whitelisted, Reducer.recurseObj(newVal, path, '/'), path, '/', true)
        }
        this.log('whitelisted =', whitelisted)
        newVal = whitelisted
      } else {
        newVal = {}
      }
    }

    if (Array.isArray(this.blacklist)) { // execute eventual blacklist and reset value to his default
      this.log('entering blacklisted prop:', this.blacklist)
      const maps: {[key:string]:any} = {}
      for (const toSave of this.blacklistIgnore) {
        maps[toSave] = Reducer.recurseObj(newVal, toSave, '/')
      }
      for (const toNull of this.blacklist) {
        this.log('setting path: ', toNull)
        Reducer.setRecurseObj(newVal, Reducer.recurseObj(defState, toNull, '/'), toNull, '/', true)
      }
      for (const toSave of this.blacklistIgnore) {
        Reducer.setRecurseObj(newVal, maps[toSave], toSave, '/', true)
      }
    }
    if (Array.isArray(this.maplist)) {
      this.log('entering mapped prop:', this.maplist)
      for (const mapObj of this.maplist) {
        this.log('currentMap: ', mapObj)
        if (mapObj && mapObj.from && mapObj.to && !(mapObj.from === '') && !(mapObj.to === '')) {
          this.log('setting map now from: "', Reducer.recurseObj(newVal, mapObj.from, '/'), '"to: "', Reducer.recurseObj(newVal, mapObj.to, '/'), '"')
          Reducer.setRecurseObj(newVal, Reducer.recurseObj(newVal, mapObj.from, '/'), mapObj.to, '/', true)
          this.log('new to: "', Reducer.recurseObj(newVal, mapObj.to, '/'), '"')
        }
        this.log('currentMap finished')
      }
    }

    if (Array.isArray(this.entryPoint)) {
      for (const entry of this.entryPoint) {
        entry(newVal, defState)
      }
    }

    return newVal
  }

  log = (...args:any[]) => {
    if (this.logs) {
      console.log(...args)
    }
  }

  static setRecurseObj (obj:any, value:any, path:string, separator:string, setrecursively:boolean): any {
    const recurse = path.split(separator)
    let level = 0
    return recurse.reduce((a, b) => {
      level++
      if (setrecursively && typeof a[b] === 'undefined' && level !== recurse.length) {
        a[b] = {}
        return a[b]
      }
      if (level === recurse.length) {
        a[b] = value
        return value
      } else {
        return a[b]
      }
    }, obj)
  }

  static recurseObj (obj:any, path:string, separator:string):any {
    const recurse = path.split(separator)
    let retVal = obj
    for (const key of recurse) {
      if (retVal) {
        retVal = retVal[key]
      }
    }
    return retVal
  }
}
