import { GetterTree } from 'vuex'
import { TitleBarState } from './types'
import { BaseState } from '../../../../types'

function isNNaN (num: number): boolean {
  return isNaN(num) || num === null
}

export const getters: GetterTree<TitleBarState, BaseState> = {
  appName: function (state) {
    if (!isNNaN(state.appNameSubStart) && !isNNaN(state.appNameSubEnd)) {
      return state.appName.substring(state.appNameSubStart, state.appNameSubEnd)
    } else if (!isNNaN(state.appNameSubStart) && isNNaN(state.appNameSubEnd)) {
      return state.appName.substring(state.appNameSubStart)
    } else if (!isNNaN(state.appNameSubEnd) && isNNaN(state.appNameSubStart)) {
      return state.appName.substring(0, state.appNameSubEnd)
    } else {
      return state.appName
    }
  }
}
