import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'
import 'flag-icon-css/css/flag-icons.css'
import IconComponent from '@/components/IconComponent.vue'
Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      fullMoon: {
        component: IconComponent,
        props: {
          icon: 'full-moon',
          alt: 'Full Moon',
          path: '/img'
        }
      },
      autoDarkLight: {
        component: IconComponent,
        props: {
          icon: 'auto-dark-light',
          alt: 'Auto Dark Light',
          path: '/img',
          fetchSvg: true
        }
      }
    }
  }
})
