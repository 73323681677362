import { Module, ModuleTree } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ApplicationSettingsState } from './types'
import { BaseState } from '../../../types'
import { TitleBar } from './TitleBar'
import { ThemeEngine } from './ThemeEngine'
import { Localization } from './Localization'

export const defaultState = (): ApplicationSettingsState => {
  return {
    typeIcon: 'mdi-application-cog'
  }
}

export const modules: ModuleTree<BaseState> = {
  TitleBar,
  ThemeEngine,
  Localization
}

export const state = defaultState()

export const ApplicationSettings: Module<ApplicationSettingsState, BaseState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}
