import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import './plugins/portal'
import VueFlicking from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'

Vue.use(VueFlicking)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
