import { ActionTree } from 'vuex'
import { BooksState, CollectionManifest, CollectionMetadata, VolumeManifest, VolumeMetadata, VolumeTOC } from './types'
import { BaseState } from '../../../../types'
import Vue from 'vue'

async function sFetchOrDef<T> (url: string, def: T): Promise<T> {
  try {
    const response = await fetch(url)
    if (response.status >= 200 && response.status < 400) {
      return await response.json() as T
    } else {
      return def
    }
  } catch (e) {
    return def
  }
}
async function fetchOrDef<T> (url: string, def?: T): Promise<T> {
  const response = await fetch(url)
  if (response.status >= 200 && response.status < 400) {
    return await response.json() as T
  } else if (typeof def !== 'undefined') {
    return def
  } else throw new Error('No Default Value and No Response.')
}

export const actions: ActionTree<BooksState, BaseState> = {
  scanLibrary: async ({ commit, state }) => {
    try {
      const baseUrl = '/books/'
      const collections = await fetchOrDef<string[]>(baseUrl + 'collections.json', [])
      for (const collection of collections) {
        console.log('collection:', collection)
        const collectionManifest = await fetchOrDef<CollectionManifest>(baseUrl + collection + '/manifest.json')
        console.log('manifest:', collectionManifest)
        const collectionMetadata: CollectionMetadata = {
          id: collection,
          title: collectionManifest.title,
          logo: baseUrl + collection + '/' + collectionManifest.logo,
          type: collectionManifest.type,
          volumes: {}
        }
        for (const volume of collectionManifest.volumes) {
          const volumeManifest = await fetchOrDef<VolumeManifest>(baseUrl + collection + '/' + volume + '/volume.json')
          const volumeTOC = await sFetchOrDef<VolumeTOC>(
            baseUrl + collection + '/' + volume + '/toc.json',
            { arc: '__full', chapters: [{ title: 'fullBook', file: 'index.md' }] }
          )
          const notes = await sFetchOrDef<Record<string, string>>(baseUrl + collection + '/' + volume + '/notes.json', {})

          const volumeMetadata: VolumeMetadata = {
            ...volumeManifest,
            basePath: baseUrl + collection + '/' + volume + '/',
            notes,
            collectionLogo: collectionMetadata.logo,
            tableOfContent: volumeTOC
          }
          Vue.set(collectionMetadata.volumes, volumeManifest.id, volumeMetadata)
        }
        commit('SET_COLLECTION', { collectionName: collection, collection: collectionMetadata })
      }
    } catch (e) { console.log('Error on scanLibrary:', e) }
  },
  load: async ({ dispatch }) => {
    dispatch('scanLibrary')
  }
}
