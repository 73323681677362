/* eslint-disable @typescript-eslint/no-explicit-any */
export type CollectionType = 'markdownBook' | 'pointer'
export const RecentsCollectionId = 'recents'

export interface CollectionManifest {
    title: string
    type: CollectionType
    logo: string
    order: string
    volumes: string[]
}

export interface VolumeManifest {
    id: string
    cover: string
    title: string
    progress:number
}

export interface VolumeChapter {
    title: string
    file: string
}
export interface VolumeTOC {
    arc: string,
    chapters: VolumeChapter[]
}

export interface VolumeMetadata extends VolumeManifest {
    basePath: string
    tableOfContent: VolumeTOC
    notes: Record<string, string>
    collectionLogo: string
    subTitle?: string
}

export interface VolumeIdentifier {
    collectionId: string
    volumeId: string
}
export interface BookHistory {
    time: Date
    book: VolumeIdentifier
}
export interface VolumeCurrentPosition {
    chapter: number
    size: number
    page: number
}

export interface CollectionMetadata {
    id: string
    title: string
    type: CollectionType
    logo: string
    volumes: Record<string, VolumeMetadata> | Record<string, VolumeIdentifier>
}

export interface BooksState {
    library: Record<string, CollectionMetadata>,
    lastBooks: BookHistory[],
    lastBooksMaxItems: number,
    lastBooksSortDesc: boolean,
    booksPosition: Record<string, VolumeCurrentPosition>
    isReading: boolean
}
