import {
  VuetifyThemes,
  VuetifyThemeVariant,
  VuetifyThemeItem,
  VuetifyParsedThemeItem
} from 'vuetify/types/services/theme'

export enum DarkState {Dark, Light, System}

export type ThemeEngineParsedThemeItem = Partial<VuetifyParsedThemeItem>;
export type ThemeEngineThemeItem = VuetifyThemeItem;

export interface ThemeEngineThemeVariant extends VuetifyThemeVariant {
    background?: ThemeEngineThemeItem;
    'background-contrast'?: ThemeEngineThemeItem;
    'primary-contrast'?: ThemeEngineThemeItem;
    'secondary-contrast'?: ThemeEngineThemeItem;
}

export enum ThemeEngineVariationType {
    Dark = 'dark',
    Light = 'light'
}

export interface ThemeEngineTheme extends VuetifyThemes {
    dark: ThemeEngineThemeVariant;
    light: ThemeEngineThemeVariant;
}

export interface ThemeEngineThemeView extends ThemeEngineTheme {
    key: string;
    active: boolean;
}

export interface ThemeEngineList {
    [key: string]: ThemeEngineTheme;
}
export interface ThemeEngineViewList {
    [key: string]: ThemeEngineThemeView;
}

export interface ThemeEngineState {
    themeList: ThemeEngineList;
    currentTheme: string;
    isDark: boolean;
    darkState: DarkState;
}

export interface ThemeStoreOption {
    background: string;
    name: string;
    type: 'light' | 'dark';
}
