
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

@Component({})
export default class ClosedBook extends Vue {
  @Prop({ type: String, required: false, default: '' })
    coverPath!: string

  @Prop({ type: String, required: false, default: '' })
    title!: string

  @Prop({ type: Boolean, required: false, default: false })
    translationInProgress!: boolean

  @Prop({ type: Number, required: false, default: 0 })
    translationPercent!:number

  coverUuid = uuid()

  get hasCover ():boolean {
    return typeof this.coverPath === 'string' && this.coverPath !== ''
  }
}
