import { ActionTree } from 'vuex'
import { ApplicationSettingsState } from './types'
import { BaseState } from '../../../types'

export const actions: ActionTree<ApplicationSettingsState, BaseState> = {
  load: async ({ dispatch }) => {
    await dispatch('ThemeEngine/load')
    await dispatch('Localization/load')
  }
}
