import { GetterTree } from 'vuex'
import { LocalizationState } from './types'
import { BaseState } from '../../../../types'
import { compareInt } from '@/store/helpers'
import { guessBrowserLanguage } from '@/plugins/i18n/helper'
import i18n from '@/plugins/i18n'

export const getters: GetterTree<LocalizationState, BaseState> = {
  currentLanguage (state): string {
    let retVal = state.currentLocale
    if (!(typeof retVal === 'undefined') && !(retVal === '') && !(retVal === 'auto')) {
      i18n.locale = retVal
    } else {
      i18n.locale = guessBrowserLanguage(state.languages, i18n.fallbackLocale as string)
      retVal = 'auto'
    }
    return retVal
  },
  selectableLanguages (state): {
      pos: number,
      text: string,
      value: string
    }[] {
    let i = 1
    const list: {
      pos: number,
      text: string,
      value: string
    }[] = state.languages.slice(0).map(
      (curLang) => {
        const obj = {
          pos: i,
          text: curLang.title,
          value: curLang.language
        }
        i++
        return obj
      }
    )
    list.push({
      pos: 0,
      text: '',
      value: 'auto'
    })
    return list.sort(
      (a, b) => {
        return compareInt(a.pos, b.pos, false)
      }
    )
  }
}
