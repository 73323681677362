import { Module, ModuleTree } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ReaderSettingsState } from './types'
import { BaseState } from '../../../types'
import { Books } from './Books'
import { Lexer } from './Lexer'

export const defaultState = (): ReaderSettingsState => {
  return {
    typeIcon: 'mdi-application-cog'
  }
}

export const modules: ModuleTree<BaseState> = {
  Books,
  Lexer
}

export const state = defaultState()

export const ReaderSettings: Module<ReaderSettingsState, BaseState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}
