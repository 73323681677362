
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Component, Prop } from 'vue-property-decorator'
import LanguageIcon from '@/components/LanguageIcon.vue'
import { FlagType } from '@/components/Flag.vue'
import ColoredSelect from '@/components/ColoredSelect.vue'

const storePrefix = 'Settings/Application/'

export interface LocaleOBJ {
  pos: number,
  text: string,
  value: string
}

@Component(
  {
    computed: {
      ...mapGetters(storePrefix + 'Localization', ['selectableLanguages'])
    },
    components: {
      LanguageIcon,
      ColoredSelect
    }
  }
)
export default class LocalizationSettings extends Vue {
  @Prop({ type: Number, default: 10 })
    elevation!: number

  public static col = 1
  public static row = 1

  selectableLanguages!: LocaleOBJ[]

  FlagType = FlagType

  get currentLanguage ():LocaleOBJ {
    const language = this.$store.getters[storePrefix + 'Localization/currentLanguage'] as string
    for (const curLang of this.selectableLanguages) {
      if (curLang.value === language) {
        return curLang
      }
    }
    return { pos: -1, value: '', text: '' }
  }

  set currentLanguage (locale:LocaleOBJ) {
    this.$store.commit(storePrefix + 'Localization/SET_CURRENT_LANGUAGE', locale.value)
  }

  saveI18n (value:LocaleOBJ):void { this.currentLanguage = value }
}
