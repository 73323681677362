import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { LexerState } from './types'
import { BaseState } from '../../../../types'
export const defaultState = (): LexerState => {
  return {
    terms: []
  }
}

export const state = defaultState()

export const Lexer: Module<LexerState, BaseState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
  }
}
