import { MutationTree } from 'vuex'
import { TitleBarState } from './types'

export const mutations: MutationTree<TitleBarState> = {
  SET_REVERSED (state) {
    state.isReversed = true
  },
  SET_NORMAL (state) {
    state.isReversed = false
  },
  TOGGLE_REVERSED (state) {
    state.isReversed = !state.isReversed
  },
  SET_CURRENT_PAGE (state, currentPage) {
    state.currentPage = currentPage
  },
  SET_APP_NAME (state, appName) {
    state.alternateAppName = appName
  },
  HIDE_MENU_BTN (state) {
    state.hideMenuBtn = true
  },
  SHOW_MENU_BTN (state) {
    state.hideMenuBtn = false
  },
  TOGGLE_MENU_BTN (state) {
    state.hideMenuBtn = !state.hideMenuBtn
  },
  SET_APPLICATION_BORDER (state, border: 0 | 1 | 2 | 3) {
    state.applicationBorder = border
  },
  SET_SHOWING_HOME_BUTTON (state, showButton: boolean) {
    state.isShowingHomeButton = showButton
  },
  SET_SHOW_TITLE_BAR (state, show: boolean) {
    state.showTitleBar = show
  }
}
