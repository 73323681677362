
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { SettingsTabs } from '@/store/modules/Settings/types'
import { DraggableGridObj, mappedDraggableGridObj, unmappedDraggableGridObj } from '@/components/DraggableGridHelper'
import DraggableGrid from '@/components/DraggableGrid.vue'
import settings from '@/components/settings'
import { cloneDeep } from 'lodash'

// const components = {

// }
// console.log('components:', components, 'grid:', settings.grids)
@Component({
  components: {
    DraggableGrid,
    ...settings.exports
  }
})
export default class SettingDialog extends Vue {
  tab = 0
  get tabs (): SettingsTabs[] {
    return this.$store.getters['Settings/types']
  }

  get curTabName (): string {
    return this.tabsName[this.tab]
  }

  get tabsName (): string[] {
    return this.$store.getters['Settings/tabsName']
  }

  elements = mappedDraggableGridObj(this.tabsName, typeof this.$store.state.Settings.Application.settingGrid === 'undefined' ? settings.grids : cloneDeep(this.$store.state.Settings.Application.settingGrid))

  get elementsMap (): DraggableGridObj[] {
    return this.elements
  }

  @Watch('elements', { deep: true })
  watchElementsMap (newValue: DraggableGridObj[]):void {
    this.$store.commit('Settings/Application/storeGrid', unmappedDraggableGridObj(this.tabsName, newValue))
  }

  get variantClass (): string {
    if (this.$vuetify.theme.dark) {
      return 'dark'
    } else {
      return 'light'
    }
  }
}
