import { ThemeEngineTheme } from '@/store/modules/Settings/Application/ThemeEngine/types'
import colors from 'vuetify/lib/util/colors'

export default {
  light: {
    primary: colors.pink.darken2,
    secondary: colors.pink.lighten1,
    background: colors.pink.lighten4,
    accent: colors.pink.darken4,
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  },
  dark: {
    primary: colors.pink.darken4,
    secondary: colors.pink.darken3,
    background: colors.pink.darken1,
    accent: colors.pink.lighten4,
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  }
} as ThemeEngineTheme

export const themeName = 'Pinky Marshmellow'
