
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ColoredSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
    elements!:unknown[]

  @Prop({ type: Object, default: () => ({}) })
    value!:unknown

  @Prop({ type: Object, default: () => ({}) })
    menuProps!:Record<string, unknown>

  @Prop({ type: String, default: '' })
    menuColor!:string

  open = false

  get current (): {element:unknown, index:number} {
    const index = this.elements.indexOf(this.value)
    if (index >= 0) {
      return {
        element: this.value,
        index
      }
    }
    return {
      element: this.value,
      index: -1
    }
  }

  onChange (elementIndex:number): void {
    this.open = false
    this.$emit('change', this.elements[elementIndex])
  }

  get curMenuProps (): Record<string, unknown> {
    return { ...this.menuProps, activator: this.$refs.actbtn }
  }
}
