import { MutationTree } from 'vuex'
import { ThemeEngineState, DarkState } from './types'
import Vuetify from '@/plugins/vuetify'
import { Theme } from 'vuetify/types/services/theme'

interface IVTheme extends Theme {
  applyTheme:()=>void
}

const VTheme:IVTheme = Vuetify.framework.theme as IVTheme

export const mutations: MutationTree<ThemeEngineState> = {
  SET_CURRENT_THEME: function (state, newTheme:string) {
    if (state.themeList[newTheme]) {
      VTheme.themes = state.themeList[newTheme]
      VTheme.applyTheme()
      state.currentTheme = newTheme
    }
  },
  SET_DARK: function (state, isDark: boolean) {
    Vuetify.framework.theme.dark = isDark
    state.isDark = isDark
  },
  SET_DARK_STATE: function (state, darkState: DarkState) {
    state.darkState = darkState
  }
}
