export class LocaleInfo {
  public flag = ''
  public language = ''
  public title = ''
  constructor ({ flag = '', language = '', title }: {flag?: string; language?: string; title: string}) {
    this.flag = flag
    this.language = language
    this.title = title
  }

  formatted (): string {
    return this.title + ' (' + this.language + ')'
  }
}

export function guessBrowserLanguage (availableLanguages: LocaleInfo[], fallback: string): string {
  if (typeof navigator.languages === 'undefined') {
    for (const elem of availableLanguages) {
      if (navigator.language.includes(elem.language)) {
        return elem.language
      }
    }
  } else {
    for (const navLang of navigator.languages) {
      for (const elem of availableLanguages) {
        if (navLang.includes(elem.language)) {
          return elem.language
        }
      }
    }
  }
  return fallback
}
