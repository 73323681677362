
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BrandedAppBar from './components/BrandedAppBar.vue'

@Component({ components: { BrandedAppBar } })
export default class App extends Vue {
  mounted ():void {
    this.$store.dispatch('load')
  }
}
