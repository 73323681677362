import { GetterTree } from 'vuex'
import { ThemeEngineState, ThemeEngineViewList, ThemeEngineVariationType } from './types'
import { BaseState } from '../../../../types'
import cloneDeep from 'lodash/fp/cloneDeep'

export const getters: GetterTree<ThemeEngineState, BaseState> = {
  currentTheme (state) {
    const curTheme = state.themeList[state.currentTheme]
    if (curTheme) {
      return curTheme
    }
    return state.themeList.qBitTron
  },
  currentThemeView (state, getters) {
    console.log('getters:', getters)
    const curTheme = getters?.themeListWithKey[state.currentTheme]
    if (curTheme) {
      return curTheme
    }
    return { ...cloneDeep(state.themeList.qBitTron), key: 'qBitTron', active: true }
  },
  currentVariationType (state):ThemeEngineVariationType {
    return state.isDark ? ThemeEngineVariationType.Dark : ThemeEngineVariationType.Light
  },
  themeListWithKey (state) {
    const keys = Object.keys(state.themeList)
    const cloned = cloneDeep(state.themeList)
    const newList: ThemeEngineViewList = {}
    for (const key of keys) {
      newList[key] = {
        ...cloned[key],
        key,
        active: state.currentTheme === key
      }
    }
    return newList
  }
}
