import { ActionTree } from 'vuex'
import { DarkState, ThemeEngineState } from './types'
import { BaseState } from '../../../../types'

class DarkStateManager extends EventTarget {
  systemIsDark: boolean
  darkState: DarkState
  constructor (darkState: DarkState) {
    super()
    this.systemIsDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    this.darkState = darkState
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', evnt => {
      this.systemIsDark = evnt.matches
      if (this.darkState === DarkState.System) {
        this.dispatchEvent(new CustomEvent('isDarkChange', { detail: this.systemIsDark }))
      }
    })
  }

  setDarkState (darkState: DarkState): void {
    this.darkState = darkState
    switch (darkState) {
      case DarkState.Dark:
        this.dispatchEvent(new CustomEvent('isDarkChange', { detail: true }))
        break
      case DarkState.Light:
        this.dispatchEvent(new CustomEvent('isDarkChange', { detail: false }))
        break
      case DarkState.System:
        this.dispatchEvent(new CustomEvent('isDarkChange', { detail: this.systemIsDark }))
        break
      default:
        console.log('mmm strange darkState:', darkState)
        break
    }
  }
}

let darkStateManager: DarkStateManager

export const actions: ActionTree<ThemeEngineState, BaseState> = {
  setCurrentTheme: async ({ commit, state }, newTheme: string) => {
    const wantedTheme = state.themeList[newTheme]
    if (wantedTheme) {
      commit('SET_CURRENT_THEME', newTheme)
    }
  },
  setDarkState: async ({ commit }, darkState: DarkState) => {
    darkState = typeof darkState === 'string' ? DarkState[darkState] as unknown as DarkState : darkState
    commit('SET_DARK_STATE', darkState)
    darkStateManager.setDarkState(darkState)
  },
  load: async ({ state, dispatch, commit }) => {
    darkStateManager = new DarkStateManager(state.darkState)
    dispatch('setCurrentTheme', state.currentTheme)
    darkStateManager.addEventListener('isDarkChange', (e) => {
      console.log('evnt:', e)
      commit('SET_DARK', (e as CustomEvent).detail as boolean)
    })
    dispatch('setDarkState', state.darkState)
  }
}
