import { ActionTree } from 'vuex'
import { LexerState } from './types'
import { BaseState } from '../../../../types'
import Vue from 'vue'

export const actions: ActionTree<LexerState, BaseState> = {
  load: () => {
    console.info('loading lexer')
  }
}
