import { Module } from 'vuex'
import { getts } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { BooksState } from './types'
import { BaseState } from '../../../../types'
export const defaultState = (): BooksState => {
  return {
    library: {},
    isReading: false,
    lastBooks: [],
    lastBooksMaxItems: 5,
    lastBooksSortDesc: true,
    booksPosition: {}
  }
}

export const state = defaultState()

export const Books: Module<BooksState, BaseState> = {
  namespaced: true,
  state,
  getters: getts,
  actions,
  mutations,
  modules: {
  }
}
