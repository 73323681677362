import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { TitleBarState } from './types'
import { BaseState } from '../../../../types'

export const defaultState = (): TitleBarState => {
  return {
    isReversed: false,
    appName: document.title,
    appNameSubStart: 0,
    appNameSubEnd: NaN,
    alternateAppName: '',
    currentPage: '',
    hideMenuBtn: false,
    applicationBorder: 1,
    isShowingHomeButton: true,
    showTitleBar: true
  }
}

export const state = defaultState()

export const TitleBar: Module<TitleBarState, BaseState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
