import { ThemeEngineTheme } from '@/store/modules/Settings/Application/ThemeEngine/types'
import colors from 'vuetify/lib/util/colors'

export default {
  light: {
    primary: colors.grey.lighten1,
    secondary: colors.grey.lighten2,
    background: colors.grey.lighten5,
    accent: colors.grey.darken2,
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  },
  dark: {
    primary: colors.grey.darken3,
    secondary: colors.grey.darken2,
    background: colors.grey.darken1,
    accent: colors.grey.lighten2,
    success: colors.green,
    info: colors.blueGrey,
    warning: colors.amber,
    error: colors.red
  }
} as ThemeEngineTheme
