import Vue from 'vue'
import { v4 as uuid } from 'uuid'
export interface iDraggableGridElement {
    'name': string,
    'tag': string,
    'cols'?: boolean | string | number,
    'cols-lg'?: boolean | string | number,
    'cols-md'?: boolean | string | number,
    'cols-sm'?: boolean | string | number,
    'cols-xl'?: boolean | string | number,
    'key-id': string
}

export class DraggableGridElement implements iDraggableGridElement {
  'name': string
  'tag': string
  'cols'?: boolean | string | number
  'cols-lg'?: boolean | string | number
  'cols-md'?: boolean | string | number
  'cols-sm'?: boolean | string | number
  'cols-xl'?: boolean | string | number
  private 'priv-key-id': string | undefined = undefined

  get 'key-id' ():string {
    if (this['priv-key-id'] === undefined) {
      this['priv-key-id'] = uuid()
    }
    return this['priv-key-id']
  }

  set 'key-id' (id: string) {
    console.warn('Attention key-id of a DraggableGridElement can\'t be changed is an autogenerated property')
  }

  constructor (
    name: string,
    tag = 'div',
    cols: boolean | string | number | undefined = undefined,
    lg: boolean | string | number | undefined = undefined,
    md: boolean | string | number | undefined = undefined,
    sm: boolean | string | number | undefined = undefined,
    xl: boolean | string | number | undefined = undefined
  ) {
    this.name = name
    this.cols = cols
    this.tag = tag
    this['cols-lg'] = lg
    this['cols-md'] = md
    this['cols-sm'] = sm
    this['cols-xl'] = xl
  }

  static from (element: iDraggableGridElement): DraggableGridElement {
    return new DraggableGridElement(
      element.name,
      element.tag,
      element.cols,
      element['cols-lg'],
      element['cols-md'],
      element['cols-sm'],
      element['cols-xl']
    )
  }
}

export interface iDraggableGridMap {
    elements: DraggableGridElement[][]
}

export function newMap (
  elements: DraggableGridElement[][] = [[]]
): iDraggableGridMap {
  return {
    elements
  }
}

export interface iDraggableMap {
  [key:string]: string[][] | undefined
}

export interface iMappableObj {
  col: number
  row: number
}

export class DraggableGridObj implements iDraggableGridMap {
  elements: DraggableGridElement[][]
  constructor (
    elements: DraggableGridElement[][] = [[]]
  ) {
    this.elements = elements
  }

  add (row: number, element: iDraggableGridElement): DraggableGridObj {
    if (!Array.isArray(this.elements[row])) {
      Vue.set(this.elements, row, [])
    }
    this.elements[row].push(DraggableGridElement.from(element))
    return this
  }
}

export function mappedDraggableGridObj (mapOrder: string[], map: iDraggableMap): DraggableGridObj[] {
  // console.log('called mappedDraggableGridObj with(mapOrder:', mapOrder, ',map:', map, ')')
  const elements: DraggableGridObj[] = []
  for (const current of mapOrder) {
    const curMap = map[current.toLocaleLowerCase()]
    let maps
    if (typeof curMap !== 'undefined') {
      maps = curMap.map<DraggableGridElement[]>((val) => val.map<DraggableGridElement>((val2) => new DraggableGridElement(val2, val2)))
    }
    elements.push(
      new DraggableGridObj(maps || [])
    )
  }
  return elements
}

export function unmappedDraggableGridObj (mapOrder: string[], map: DraggableGridObj[]): iDraggableMap {
  // console.log('called unmappedDraggableGridObj with(mapOrder:', mapOrder, ',map:', map, ')')
  const elements: iDraggableMap = {}
  let i = 0
  for (const current of mapOrder) {
    if (typeof map[i] !== 'undefined') {
      elements[current.toLocaleLowerCase()] = map[i].elements.map<string[]>((value) => {
        return value.map<string>((sval) => {
          return sval.name
        })
      })
    }
    i++
  }
  return elements
}

export function armonizeCol (col: boolean | string | number): boolean | string | number {
  if (typeof col === 'undefined') return false
  if (typeof col === 'boolean') return col
  if (typeof col === 'string') {
    try {
      return Math.round(12 / Number.parseInt(col))
    } catch (e) {
      return false
    }
  }
  return Math.round(12 / col)
}
